<template>
  <div @click="closeOpenSearch">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-card title="Products">
              <b-row>
                <b-col cols="12">
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="7">
                        <b-form-input
                          id="searchText"
                          class="mt-2"
                          style="height: 37.45px"
                          type="search"
                          :state="orderData.variants.length == 0 ? false : true"
                          v-model="searchText"
                          placeholder="Search For Add Item"
                        />
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          @click="searchResultsubmit(searchText)"
                          class="mt-2"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noResult"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 38px;
                          height: 380px !important;
                          overflow: scroll;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                          background-color: #fff;
                        "
                        v-if="resultShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            v-b-modal.modal-prevent-closing
                            @click="setData(item.id, item.name, item.price)"
                            v-for="(item, index) in productsSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <div class="d-inline">
                              <small style="padding-left: 10px"
                                >Price: {{ item.price }}</small
                              >
                              <small style="padding-left: 10px"
                                >SKU: {{ item.sku }}</small
                              >
                            </div>
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchResult"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                      <b-modal
                        id="modal-prevent-closing"
                        cancel-variant="outline-secondary"
                        @ok="
                          addedProductSubmit(
                            addedProduct.variant_id,
                            addedProduct.name,
                            addedProduct.price,
                            addedProduct.quantity
                          )
                        "
                        ok-title="Add"
                        cancel-title="Close"
                        centered
                        title="Add Product"
                      >
                        <b-form>
                          <b-col md="6">
                            <b-form-group label="Quantity" label-for="quantity">
                              <validation-provider
                                #default="{ errors }"
                                name="quantity"
                                rules="required|regex:^[0]*[1-9][0-9]*$"
                              >
                                <b-form-input
                                  id="quantity"
                                  v-model="addedProduct.quantity"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-form>
                      </b-modal>
                      <b-col cols="12">
                        <b-row>
                          <b-col
                            v-if="orderData.variants.length"
                            class="pt-1 pb-1 pr-5 pl-5"
                            cols="12"
                          >
                            <h2
                              v-if="orderData.variants.length"
                              class="mr-3 pt-2"
                            >
                              Items List:
                            </h2>
                            <b-row
                              v-if="orderData.variants.length"
                              v-for="(item, index) in orderData.variants"
                              :key="index"
                            >
                              <b-col cols="12">
                                <hr />
                              </b-col>
                              <b-col class="pl-5" cols="5">
                                <h4>{{ item.name }}</h4>
                                <small style="padding-left: 10px"
                                  >Price: {{ item.price }}</small
                                >
                              </b-col>
                              <b-col
                                cols="3"
                                style="margin-top: 5px"
                                class="
                                  d-flex
                                  justify-content-end
                                  align-items-center
                                "
                              >
                                <b-button
                                  variant="warning"
                                  v-b-modal.modal-prevent-update
                                  @click="variant_Item = item"
                                >
                                  Update quantity
                                </b-button>
                              </b-col>
                              <b-modal
                                id="modal-prevent-update"
                                cancel-variant="outline-secondary"
                                @ok="updateQun(variant_Item, quantity)"
                                ok-title="Add"
                                cancel-title="Close"
                                centered
                                title="Add Product"
                              >
                                <b-form>
                                  <b-col md="6">
                                    <b-form-group
                                      label="Quantity"
                                      label-for="quantity"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="quantity"
                                        rules="required|regex:^[0]*[1-9][0-9]*$"
                                      >
                                        <b-form-input
                                          id="quantity"
                                          v-model="quantity"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                </b-form>
                              </b-modal>
                              <b-col md="2">
                                <b-form-group
                                  label="Quantity"
                                  label-for="Quantity"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Quantity"
                                  >
                                    <b-form-input
                                      disabled
                                      min="1"
                                      id="Quantity"
                                      type="number"
                                      v-model="item.quantity"
                                      @input="recalcCost()"
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="15"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="2" class="mb-50">
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  variant="flat-danger"
                                  v-if="orderData.variants.length > 1"
                                  class="mt-0 mt-md-2"
                                  @click="removeItem(index)"
                                >
                                  <feather-icon icon="XIcon" class="mr-25" />
                                </b-button>
                              </b-col>
                              <b-col cols="12">
                                <hr />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- <b-col cols="12">
                                                <b-card title="Bundles">
                                                    <b-row>
                                                        <b-col cols="12">
                                                            <div style="position: relative">
                                                                <b-row>
                                                                    <b-col cols="7">
                                                                        <b-form-input id="searchText" class="mt-2" style="height:37.45px;" type="search" :state="orderData.variants.length == 0 ? false : true" v-model="searchText2" placeholder="Search For Add Item" />
                                                                    </b-col>
                                                                    <b-col cols="2">
                                                                        <b-button @click="searchResultBundle(searchText2)" class="mt-2" variant="outline-primary">Search</b-button>
                                                                    </b-col>
                                                                    <b-alert variant="warning" style="margin: 0.438rem 1rem;" :show="noResultBund">
                                                                        <div class="alert-body">
                                                                            No Result Found.
                                                                        </div>
                                                                    </b-alert>
                                                                    <b-col class="mt-2" style="position:absolute;z-index: 9999; padding: 0.438rem 1rem;top:35px;height: 380px!important;overflow: scroll;scroll-padding: 0px!important;padding-right: 0;" v-if="resultShowBund" cols="12">
                                                                        <b-list-group>
                                                                            <b-list-group-item @click="addBundle(item.bundle_id, item.name, item.bundPrice, item.originalPrice)" v-for="(item,index) in productsSRBund">
                                                                                <feather-icon icon="" class="mr-75" />
                                                                                <p style="font-weight: 600;"> {{item.name}}</p>
                                                                                <small style="padding-left: 10px;">Bundle Price: {{item.bundPrice}}</small>
                                                                                <small style="padding-left: 10px;">Original Price: {{item.originalPrice}}</small>
                                                                            </b-list-group-item>
                                                                        </b-list-group>
                                                                    </b-col>
                                                                    <b-col cols="12">
                                                                        <b-row>
                                                                            <b-col v-if="orderData.bundles.length" class="pt-1 pb-1 pr-5 pl-5" cols="12">
                                                                                <h2 v-if="orderData.bundles.length" class="mr-3 pt-2">Items List:</h2>
                                                                                <b-row v-if="orderData.bundles.length" v-for="(item, index) in orderData.bundles">
                                                                                    <b-col cols="12">
                                                                                        <hr>
                                                                                    </b-col>
                                                                                    <b-col class="pl-5" cols="7">
                                                                                        <h4>{{item.name}}</h4>
                                                                                        <small style="padding-left: 10px;">Bundle Price: {{item.bundPrice}}</small>
                                                                                        <small style="padding-left: 10px;">Original Price: {{item.originalPrice}}</small>
                                                                                    </b-col>
                                                                                    <b-col md="2">
                                                                                        <b-form-group label="Quantity" label-for="Quantity">
                                                                                            <validation-provider #default="{ errors }" name="Quantity">
                                                                                                <b-form-input min="1" id="Quantity" type="number" v-model="item.quantity" @input="recalcCost()" :state="errors.length > 0 ? false:null" placeholder="15" />
                                                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                                            </validation-provider>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="2" class="mb-50">
                                                                                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItemBundle(index)">
                                                                                            <feather-icon icon="XIcon" class="mr-25" />
                                                                                            <span>Remove</span>
                                                                                        </b-button>
                                                                                    </b-col>
                                                                                    <b-col cols="12">
                                                                                        <hr>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-col> -->
          <b-col>
            <b-card title="Cost">
              <b-row>
                <!-- <b-col md='6'> -->
                <!-- <b-button id="toggle-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-prevent-closing variant="outline-primary">
                                                                Add Coupon
                                                            </b-button> -->
                <!-- <div v-if="couponData != null">
                                                                <small v-if="couponActive" class="text-success">Your coupon has been worked</small>
                                                                <small v-else class="text-danger">Invalid Coupon</small>
                                                            </div>
                                                        </b-col> -->
                <b-col>
                  <p>Price: {{ cost }}</p>
                  <!-- <p>Discout: {{percentC}}%</p> -->
                  <p>Shipping: {{ shipping }}</p>
                  <p>Total: {{ total }}</p>
                </b-col>
              </b-row>
              <!-- add Coupon model -->
              <!-- <b-modal id="modal-prevent-closing" ref="my-modal" title="Submit Your Coupon" ok-title="Submit" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleOk">
                                                        <form ref="form" @submit.stop.prevent="handleSubmit">
                                                            <b-form-group :state="couponState" label="Coupon" label-for="Coupon" invalid-feedback="Coupon is required">
                                                                <b-form-input id="Coupon" v-model="couponSerial" :state="couponState" required />
                                                            </b-form-group>
                                                        </form>
                                                    </b-modal> -->
            </b-card>
          </b-col>
          <b-col cols="12">
            <!-- <b-button variant="primary" type="submit" @click.prevent="validationForm">
                            Submit
                        </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { required, integer } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

// import axios from '@/libs/axios'
export default {
  mixins: [heightTransition],
  data() {
    return {
      shipComOptions: [],
      shipComSelect: {
        text: '',
        value: '',
        price: '',
      },
      couponSerial: '',
      couponState: null,
      cost: 0,
      noResult: false,
      noResultBund: false,
      resultShow: false,
      resultShowBund: false,
      userShow: false,
      searchText: '',
      searchText2: '',
      searchUser: '',
      noUser: false,
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      orderData: {
        address: '',
        phoneone: '',
        phonetwo: '',
        type: 'cash-on-delivery',
        area_id: '',
        note: '',
        coupon_id: '',
        user_id: null,
        variants: [],
        bundles: [],
      },
      userSelect: {
        value: '',
        text: '',
      },
      usersOptions: [],
      countrySelect: {
        value: '',
        text: '',
      },
      countryOptions: [],
      citySelect: {
        value: '',
        text: '',
      },
      cityOptions: [],
      areaSelect: {
        value: '',
        text: '',
      },
      areaOptions: [],
      couponSelect: {
        value: '',
        text: '',
      },
      couponsOptions: [],
      productsSR: [],
      productsSRBund: [],
      userSR: [],
      area: true,
      city: true,
      ship: true,
      startCPrice: '',
      endCPrice: '',
      percentC: 0,
      shipping: 0,
      total: 0,
      showerrorCoup: true,
      couponActive: false,
      couponData: null,
      addedProduct: {
        variant_id: null,
        quantity: null,
        name: null,
        price: null,
      },
      currentPage: 0,
      variant_Item: null,
      quantity: null,
    }
  },
  watch: {
    countrySelect: function () {
      this.countrySelected()
    },
    citySelect: function () {
      this.citySelected()
    },
    areaSelect: function () {
      this.areaSelected()
    },
    shipComSelect: function () {
      this.shipping = this.shipComSelect.price
    },
    cost: function () {
      if (this.couponSerial != '') {
        this.handleSubmit()
      }
    },
    couponSerial: function () {
      this.showerrorCoup = true
    },
  },
  created() {
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('orders/' + this.id)
        .then((result) => {
          const data = result.data.data
          this.orderData.user_id = data.user.id
          this.searchUser = data.user.firstname + ' ' + data.user.lastname
          this.orderData.address = data.user.address
          this.orderData.area = data.area
          this.orderData.phoneone = data.phoneone
          this.orderData.phonetwo = data.phonetwo
          this.orderData.note = data.note
          this.shipping = parseFloat(data.shipping_price)
          if (data.courier != null) {
            this.ship = false
            this.shipComSelect.text = data.courier.translations.name
            this.shipComSelect.value = data.courier.id.toString()
            for (let ind in data.courier.areas) {
              if (data.courier.areas[ind].id == data.area.id) {
                this.shipComSelect.price = parseFloat(
                  data.courier.areas[ind].price
                )
              }
            }
          }
          this.orderData.variants.length = 0
          if (data.variants.length > 0) {
            for (let index in data.variants) {
              const varia = data.variants[index]
              this.orderData.variants.push({
                name: varia.name,
                variant_id: varia.id,
                quantity: varia.quantity,
                price: varia.price,
              })
            }
          }
          this.orderData.bundles.length = 0
          if (data.bundles.length > 0) {
            for (let index in data.bundles) {
              const bund = data.bundles[index]
              this.orderData.bundles.push({
                bundle_id: bund.id,
                bundPrice: bund.price,
                // originalPrice: bund.original_price,
                quantity: bund.quantity,
                name: bund.title,
              })
            }
          }
          this.cost = 0
          if (this.orderData.variants.length > 0) {
            for (let index in this.orderData.variants) {
              const qun = parseInt(this.orderData.variants[index].quantity)
              const pri = parseFloat(this.orderData.variants[index].price)
              this.cost += qun * pri
            }
          }
          if (this.orderData.bundles.length > 0) {
            for (let index in this.orderData.bundles) {
              const qun = parseInt(this.orderData.bundles[index].quantity)
              const pri = parseFloat(this.orderData.bundles[index].bundPrice)
              this.cost += qun * pri
            }
          }
          const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
          this.total = totalBeforShip + this.shipping
          if (data.coupon != null) {
            this.orderData.coupon_id = data.coupon.id
            this.couponActive = true
            axios
              .get('coupons/' + data.coupon.id)
              .then((result) => {
                const data = result.data.data
                this.couponData = data
                this.startCPrice = data.startprice
                this.endCPrice = data.endprice
                this.percentC = data.percentage
                this.couponSerial = data.code
                const totalBeforShip =
                  this.cost - this.cost * (this.percentC / 100)
                this.total = totalBeforShip + this.shipping
              })
              .catch((err) => {
                this.couponActive = false
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.length = 0
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
          const CityI = this.orderData.area
          axios
            .get('couriers/area/' + CityI.id + '/get-couriers')
            .then((result) => {
              this.ship = false
              const data = result.data.data
              this.shipComOptions.length = 0
              for (let ind in data) {
                if (data[ind].translations != null) {
                  this.shipComOptions.push({
                    text: data[ind].translations.value,
                    value: data[ind].id.toString(),
                    price: parseFloat(data[ind].price),
                  })
                }
              }
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
          axios
            .get('cities/' + CityI.city.id + '/areas')
            .then((result) => {
              const data = result.data.data
              for (let are in data) {
                this.areaOptions.push({
                  text: data[are].name,
                  value: data[are].id.toString(),
                })
                if (this.orderData.area.id == data[are].id) {
                  this.areaSelect.value = data[are].id.toString()
                  this.areaSelect.text = data[are].name
                }
              }
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
          this.countryID = this.orderData.area.city
          axios
            .get('countries')
            .then((result) => {
              const data = result.data.data
              for (let ind in data) {
                this.countryOptions.push({
                  text: data[ind].name,
                  value: data[ind].id.toString(),
                })
                if (data[ind].id == this.countryID.country.id) {
                  this.countrySelect.value = data[ind].id.toString()
                  this.countrySelect.text = data[ind].name
                }
              }
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
          axios
            .get('country/' + this.countryID.country.id + '/cities')
            .then((result) => {
              const data = result.data.data
              for (let ind in data) {
                this.cityOptions.push({
                  text: data[ind].name,
                  value: data[ind].id.toString(),
                })
                if (this.orderData.area.city.id == data[ind].id) {
                  this.citySelect.value = data[ind].id.toString()
                  this.citySelect.text = data[ind].name
                }
              }
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // submit update form
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (
            this.orderData.bundles.length > 0 ||
            this.orderData.variants.length > 0
          ) {
            this.orderData.area_id = this.areaSelect.value
            this.orderData.courier_id = this.shipComSelect.value
            if (this.couponActive) {
              this.orderData.coupon_id = this.couponData.id
            } else {
              delete this.orderData.coupon_id
            }

            axios
              .put('orders/' + this.id, this.orderData)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.back(1)
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          } else {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'Choose bundel or product first',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        }
      })
    },
    resetModal() {
      this.couponState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      axios
        .get('coupons/' + this.couponSerial + '/check/active/' + this.cost)
        .then((result) => {
          if (this.showerrorCoup) {
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Your coupon has been worked',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.showDismissibleAlert = false
          this.showerrorCoup = false
          this.$refs['my-modal'].hide()
          const data = result.data.data
          this.couponData = data
          this.startCPrice = data.startprice
          this.endCPrice = data.endprice
          this.percentC = data.percentage
          this.couponActive = true
          const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
          this.total = totalBeforShip + this.shipping
        })
        .catch((err) => {
          if (this.showerrorCoup) {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'invalid coupon',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.couponActive = false
          this.showerrorCoup = false
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    countrySelected() {
      this.city = false
      axios
        .get('country/' + this.countrySelect.value + '/cities')
        .then((result) => {
          const data = result.data.data
          this.cityOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          for (let ind in data) {
            this.cityOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    citySelected() {
      this.area = false
      axios
        .get('cities/' + this.citySelect.value + '/areas')
        .then((result) => {
          const data = result.data.data
          this.areaOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          for (let ind in data) {
            this.areaOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    areaSelected() {
      axios
        .get('couriers/area/' + this.areaSelect.value)
        .then((result) => {
          this.ship = false
          const data = result.data.data
          this.shipComOptions.length = 0
          for (let ind in data) {
            if (data[ind].translations != null) {
              this.shipComOptions.push({
                text: data[ind].translations.value,
                value: data[ind].id.toString(),
                price: parseFloat(data[ind].price),
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchUserR(text) {
      this.noUser = false
      axios
        .post('users-pagi/search', {
          search: text,
        })
        .then((result) => {
          const data = result.data.data
          this.userSR.length = 0
          for (let index in data) {
            this.userSR.push({
              id: data[index].id,
              name: data[index].firstname + ' ' + data[index].lastname,
              email: data[index].email,
              phone: data[index].phone,
            })
          }
          if (this.userSR.length > 0) {
            this.userShow = true
          } else {
            this.noUser = true
            this.userShow = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    updateQun(item, qun) {
      // var crease = null
      // if (item.quantity > qun) {
      //     crease = "decrease"
      // }else{
      //     crease = "increase"
      // }
      axios
        .get(
          'orders/' +
            this.id +
            '/products/' +
            item.variant_id +
            '/' +
            qun +
            '/update-quantity'
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          item.quantity = qun
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })

          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResultsubmit($state) {
      this.noResult = false
      this.currentPage = 1
      axios
        .get(
          'orders/' +
            this.id +
            '/variants/validate/get/20/' +
            this.searchText +
            '?page=' +
            this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data
          this.productsSR = []

          if (data.length > 0) {
            for (let index in data) {
              if (data[index].translation != null) {
                this.productsSR.push({
                  id: data[index].id,
                  name: data[index].translation.name,
                  price: data[index].sale_price,
                  sku: data[index].sku,
                })
              } else {
                this.productsSR.push({
                  id: data[index].id,
                  name: 'undefinded',
                  price: data[index].sale_price,
                  sku: data[index].sku,
                })
              }
            }
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResult($state) {
      this.noResult = false
      this.currentPage += 1
      axios
        .get(
          'orders/' +
            this.id +
            '/variants/validate/get/20/' +
            this.searchText +
            '?page=' +
            this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.productsSR.length = 0
          // this.loadMore = false
          if (data.length > 0) {
            for (let index in data) {
              if (data[index].translation != null) {
                this.productsSR.push({
                  id: data[index].id,
                  name: data[index].translation.name,
                  price: data[index].sale_price,
                  sku: data[index].sku,
                })
              } else {
                this.productsSR.push({
                  id: data[index].id,
                  name: 'undefinded',
                  price: data[index].sale_price,
                  sku: data[index].sku,
                })
              }
            }
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResultBundle(text) {
      this.noResultBund = false
      axios
        .get('bundles/index/' + text)
        .then((result) => {
          const data = result.data.data.data
          this.productsSRBund.length = 0
          for (let index in data) {
            this.productsSRBund.push({
              bundle_id: data[index].id,
              name: data[index].title,
              bundPrice: data[index].bundle_price,
              originalPrice: data[index].original_price,
            })
          }
          if (this.productsSRBund.length > 0) {
            this.resultShowBund = true
          } else {
            this.noResultBund = true
            this.resultShowBund = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addUser(id, name) {
      this.userShow = false
      this.searchUser = name
      this.orderData.user_id = id
      this.userSR.length = 0
    },
    setData(id, name, price) {
      this.addedProduct.variant_id = id
      this.addedProduct.name = name
      this.addedProduct.price = price
    },
    addedProductSubmit(id, name, price, qun) {
      axios
        .post('orders/' + this.id + '/products/attach', {
          variant_id: id,
          quantity: qun,
        })
        .then((result) => {
          this.addProduct(id, name, price, qun)
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addProduct(id, name, price, quen) {
      this.resultShow = false
      const found = this.orderData.variants.some((el) => el.variant_id === id)
      if (!found) {
        this.orderData.variants.push({
          variant_id: id,
          price: price,
          quantity: quen,
          name: name,
        })
      } else {
        const index = this.orderData.variants.findIndex(
          (x) => x.variant_id === id
        )
        this.orderData.variants[index].quantity += quen
      }
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
      this.productsSR.length = 0
    },
    addBundle(id, name, bundPrice, originalPrice) {
      this.resultShowBund = false
      const found = this.orderData.bundles.some((el) => el.bundle_id === id)
      if (!found) {
        this.orderData.bundles.push({
          bundle_id: id,
          bundPrice: bundPrice,
          originalPrice: originalPrice,
          quantity: quen,
          name: name,
        })
      } else {
        const index = this.orderData.bundles.findIndex(
          (x) => x.bundle_id === id
        )
        this.orderData.bundles[index].quantity += 1
      }
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
      this.productsSRBund.length = 0
    },
    recalcCost() {
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
    },
    closeOpenSearch() {
      if (
        this.resultShow == true ||
        this.userShow == true ||
        this.resultShowBund == true
      ) {
        this.resultShow = false
        this.userShow = false
        this.resultShowBund = false
      }
    },
    removeItem(index) {
      axios
        .get(
          'orders/' +
            this.id +
            '/products/' +
            this.orderData.variants[index].variant_id +
            '/detach'
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.orderData.variants.splice(index, 1)
          this.cost = 0
          this.cost = 0
          if (this.orderData.variants.length > 0) {
            for (let index in this.orderData.variants) {
              const qun = parseInt(this.orderData.variants[index].quantity)
              const pri = parseFloat(this.orderData.variants[index].price)
              this.cost += qun * pri
            }
          }
          if (this.orderData.bundles.length > 0) {
            for (let index in this.orderData.bundles) {
              const qun = parseInt(this.orderData.bundles[index].quantity)
              const pri = parseFloat(this.orderData.bundles[index].bundPrice)
              this.cost += qun * pri
            }
          }
          const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
          this.total = totalBeforShip + this.shipping
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    removeItemBundle(index) {
      this.orderData.bundles.splice(index, 1)
      this.cost = 0
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
 
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
.repeater-form {
  transition: 0.35s height;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}
.app-auto-suggest {
  position: relative;
}
.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
}
.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}
.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}
.suggestion-current-selected {
  background-color: $body-bg;
  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>